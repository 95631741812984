export async function insertCSPMetaTag() {
  const meta = document.createElement('meta');
  meta.httpEquiv = 'Content-Security-Policy';
  meta.content = `
    script-src-elem 'self' https://*.js.stripe.com https://js.stripe.com;
    frame-src 'self' https://*.js.stripe.com https://js.stripe.com;
    upgrade-insecure-requests;
  `;
  document.head.appendChild(meta);
}
