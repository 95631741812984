import '@fpc/common/i18n';
import { CheckoutWebComponent } from './checkout/CheckoutWebComponent';
import { fontLoader } from '@fpc/common/FontLoader';
import { FontUrls } from '@fpc/common/Fonts';
import { loadDynatraceJsIfRequired } from '@fpc/common/DynatraceJsLoader';
import { insertCSPMetaTag } from '@fpc/common/InsertCSPMetaTag';

const checkoutFontUrls = [FontUrls.regular, FontUrls.condLight];

fontLoader(checkoutFontUrls);
loadDynatraceJsIfRequired();

customElements.define('fordpay-checkout', CheckoutWebComponent);
