import { traceId } from '@fpc/reactutils/TraceIdContext';
import { statusReject } from '@fpc/api/statusReject';
import { PaymentMethodType } from '../../types/payment';

export interface TwocTwopPaymentRequest {
  paymentInfo: string;
  paymentMethodType: PaymentMethodType;
  encryptedPaymentMethodInfo: string;
}

export interface TwocTwopPaymentResponse {
  id: string;
  status: string;
  redirectionUrl: string;
}

export const makeTwocTwopPayment = async (
  request: TwocTwopPaymentRequest,
  bearerToken: string
): Promise<TwocTwopPaymentResponse> => {
  return fetch(
    `${process.env.PAYMENT_DIGITAL_SIGNATURE_SERVER}/api/twoctwop/payments`,
    {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerToken,
        Traceparent: traceId
      }
    }
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        return Promise.reject(statusReject(res));
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
