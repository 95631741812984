import React, { useContext, useEffect, useState } from 'react';
import { TwocTwopCheckoutContext } from '@fpc/reactutils/checkoutContextProvider';
import {
  Button,
  buttonPayNow,
  paymentButtonProcessing,
  translationKeys
} from '@fpc/common';
import PaymentErrorMessage from '@fpc/checkout/payu/components/PaymentErrorMessage';
import {
  ALLOWED_KEYS,
  CARD_CVV_MINIMUM_LENGTH,
  CARD_NUMBER_MINIMUM_LENGTH,
  SUPPORTED_CARDS_FOR_PH
} from '@fpc/checkout/twoctwop/TwocTwopConfig';
import { ErrorCondition } from '@fpc/common/ErrorHandler';
import { appendTwocTwopScript } from '@fpc/checkout/twoctwop/TwocTwopLoader';
import i18n from '@fpc/common/i18n';
import i18next from 'i18next';
import CardNumberInput from '@fpc/checkout/twoctwop/components/CardNumberInput';
import CardExpiryMonthInput from '@fpc/checkout/twoctwop/components/CardExpiryMonthInput';
import CardExpiryYearInput from '@fpc/checkout/twoctwop/components/CardExpiryYearInput';
import CardCvvInput from '@fpc/checkout/twoctwop/components/CardCvvInput';
import {
  makeTwocTwopPayment,
  TwocTwopPaymentRequest
} from '@fpc/api/paymentapp/MakeTwocTwopPayment';
import { PaymentMethodType } from '../../types/payment';
export interface TwocTwopCheckoutProps {}

export const TwocTwop: React.FC<TwocTwopCheckoutProps> = () => {
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
  const [icons, setIcons] = useState<string[]>([]);
  const [cardNumber, setCardNumber] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [cardCvv, setCardCvv] = useState('');

  const [isValidCardNumber, setValidCardNumber] = useState(false);
  const [isValidExpiryMonth, setValidExpiryMonth] = useState(false);
  const [isValidExpiryYear, setValidExpiryYear] = useState(false);
  const [isValidCardCvv, setValidCardCvv] = useState(false);
  const [isValidTwocTwopForm, setValidTwocTwopForm] = useState(true);

  const [isPaymentProcessing, setPaymentProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isPayButtonEnabled, setIsPayButtonEnables] = useState(false);

  const { transaction, tokens, errorDispatch } = useContext(
    TwocTwopCheckoutContext
  );

  const updateDefaultCardIcons = (): void => {
    const cardBrands: string[] = SUPPORTED_CARDS_FOR_PH.map((cardBrand) => {
      return cardBrand.toLowerCase();
    });
    setIcons(cardBrands);
  };

  useEffect(() => {
    setIsPayButtonEnables(
      isValidCardNumber &&
        isValidExpiryMonth &&
        isValidExpiryYear &&
        isValidCardCvv &&
        isValidTwocTwopForm
    );
    if (loading) {
      // Integrate Payment Token Api call
      appendTwocTwopScript()
        .then(() => {
          setLoading(false);
        })
        .catch((err: any) => {
          console.error('Error loading TwocTwop Script:', err);
          errorDispatch(ErrorCondition.Unrecoverable);
        });
    }
    updateDefaultCardIcons();
  }, [
    errorDispatch,
    isValidCardNumber,
    isValidExpiryMonth,
    isValidExpiryYear,
    isValidCardCvv,
    isValidTwocTwopForm
  ]);

  const handleCardFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if ((window as any).My2c2p) {
      (window as any).My2c2p.getEncrypted(
        '2c2p-payment-form',
        async function (
          encryptedData: {
            encryptedCardInfo: string;
          },
          errCode: number,
          errDesc: any
        ) {
          if (errCode != 0) {
            setPaymentErrorMessage(errDesc);
            setIsPayButtonEnables(false);
            setValidTwocTwopForm(false);
          } else {
            setPaymentProcessing(true);
            setIsPayButtonEnables(false);
            setPaymentErrorMessage('');
            setValidTwocTwopForm(true);
            const securePayToken = encryptedData.encryptedCardInfo;
            if (!securePayToken) {
              setPaymentErrorMessage(
                i18next.t(translationKeys.common.technicalErrorPayment)
              );
              setIsPayButtonEnables(true);
              setPaymentProcessing(false);
              return;
            }
            const paymentRequest: TwocTwopPaymentRequest = {
              paymentInfo: JSON.parse(tokens.paymentInfoToken).paymentInfo,
              paymentMethodType: PaymentMethodType.CARD,
              encryptedPaymentMethodInfo: securePayToken
            };
            // Encrypt the form Integrate Handle Payment Api call
            await handlePayment(paymentRequest);
          }
        }
      );
    } else {
      setPaymentErrorMessage(
        i18next.t(translationKeys.common.technicalErrorPayment)
      );
    }
  };

  const handlePayment = async (paymentRequest: TwocTwopPaymentRequest) => {
    try {
      const response = await makeTwocTwopPayment(
        paymentRequest,
        tokens.digitalSignature!
      );
      response.redirectionUrl
        ? (window.location.href = response.redirectionUrl)
        : setPaymentErrorMessage(
            i18next.t(translationKeys.checkout.paymentNotProcessed)
          );
    } catch (error: any) {
      setPaymentErrorMessage(
        i18next.t(translationKeys.checkout.paymentNotProcessed)
      );
    } finally {
      setIsPayButtonEnables(true);
      setPaymentProcessing(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key;
    if (!/^\d$/.test(key) && !ALLOWED_KEYS.includes(key)) {
      event.preventDefault();
    }
  };

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    const { id, value } = event.target;

    switch (true) {
      case id.includes('cardnumber'):
        setCardNumber(value);
        setValidCardNumber(
          value !== '' && value.length >= CARD_NUMBER_MINIMUM_LENGTH
        );
        setValidTwocTwopForm(true);
        setPaymentErrorMessage('');
        break;
      case id.includes('month'):
        setExpiryMonth(value);
        setValidExpiryMonth(value !== '');
        setPaymentErrorMessage('');
        setValidTwocTwopForm(true);
        break;
      case id.includes('year'):
        setExpiryYear(value);
        setValidExpiryYear(value !== '');
        setPaymentErrorMessage('');
        setValidTwocTwopForm(true);
        break;
      case id.includes('cvv'):
        setCardCvv(value);
        setValidCardCvv(
          value !== '' && value.length >= CARD_CVV_MINIMUM_LENGTH
        );
        setPaymentErrorMessage('');
        setValidTwocTwopForm(true);
        break;
      default:
        break;
    }
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    event.preventDefault();
    const { id, value } = event.target;
    if (id.includes('cardnumber')) {
      handleCardNumberBlur(value);
    } else if (id.includes('month')) {
      handleExpiryMonthBlur(value);
    } else if (id.includes('year')) {
      handleExpiryYearBlur(value);
    } else if (id.includes('cvv')) {
      handleCvvBlur(value);
    }
  }

  function handleCardNumberBlur(value: string) {
    const element = document.getElementById('ccn-help');
    if (element) {
      element.innerHTML =
        value.length < CARD_NUMBER_MINIMUM_LENGTH
          ? i18n.t<string>(translationKeys.checkout.invalidCCNMessage)
          : '';
      setValidCardNumber(value.length >= CARD_NUMBER_MINIMUM_LENGTH);
    }
  }

  function handleExpiryMonthBlur(value: string) {
    const element = document.getElementById('exp-month-help');

    if (Number(value) >= 1 && Number(value) <= 9) {
      setExpiryMonth(`0${Number(value)}`);
    }
    if (element) {
      if (value === '') {
        setValidExpiryMonth(false);
        element.innerHTML = "Expiry Month can't be empty";
      } else if (Number(value) < 1 || Number(value) > 12) {
        setValidExpiryMonth(false);
        element.innerHTML = 'Expiry Month must be between 01 and 12';
      } else {
        element.innerHTML = '';
      }
    }
  }

  function handleExpiryYearBlur(value: string) {
    const element = document.getElementById('exp-year-help');
    const currentYear = new Date().getFullYear();

    if (element) {
      if (value === '') {
        setValidExpiryYear(false);
        element.innerHTML = "Expiry Year can't be empty";
      } else if (Number(value) < currentYear) {
        setValidExpiryYear(false);
        element.innerHTML = `Invalid expiry year`;
      } else {
        element.innerHTML = '';
      }
    }
  }

  function handleCvvBlur(value: string) {
    const element = document.getElementById('cvv-help');
    if (element) {
      element.innerHTML =
        value.length < CARD_CVV_MINIMUM_LENGTH
          ? i18n.t<string>(translationKeys.checkout.invalidCVVMessage)
          : '';
      setValidCardCvv(value.length >= CARD_CVV_MINIMUM_LENGTH);
    }
  }

  return (
    <div
      className="checkout-page"
      data-testid={'TwocTwop-checkout-form'}
      style={{ backgroundColor: '#fff', padding: '10px' }}
    >
      <form id="2c2p-payment-form" onSubmit={handleCardFormSubmit}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '10px',
            gridAutoRows: 'minmax(50px, auto)'
          }}
        >
          <CardNumberInput
            cardNumber={cardNumber}
            icons={icons}
            handleKeyPress={handleKeyPress}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <CardExpiryMonthInput
            expiryMonth={expiryMonth}
            handleKeyPress={handleKeyPress}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <CardExpiryYearInput
            expiryYear={expiryYear}
            handleKeyPress={handleKeyPress}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <CardCvvInput
            cardCvv={cardCvv}
            handleKeyPress={handleKeyPress}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </div>
        {paymentErrorMessage !== '' && (
          <PaymentErrorMessage errorMessage={paymentErrorMessage} />
        )}
        <div style={{ gridArea: '3 / 1 / auto / -1' }}>
          <Button
            id="pay-button"
            disabled={!isPayButtonEnabled}
            data-testid={'pay-button'}
            style={{ marginTop: '1em' }}
            type={'submit'}
          >
            {isPaymentProcessing
              ? paymentButtonProcessing()
              : buttonPayNow(transaction.amount, transaction.currency)}
          </Button>
        </div>
      </form>
    </div>
  );
};
